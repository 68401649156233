jQuery(document).ready(function($) {
	/* Mobile Menu */
	const mobileNavigationMenuSelector = ".mobile-navigation-menu"
	const menu = new Mmenu(mobileNavigationMenuSelector, {
		offCanvas: {
			position: "right",
		},
		screenReader: {
			aria: true,
			text: true,
		},
		hooks: {
			"open:before": () => {
				if ($(".main-slideshow").length) {
					$(".main-slideshow").cycle("pause");
				}
			},
			"close:before": () => {
				if ($(".main-slideshow").length) {
					$(".main-slideshow").cycle("resume");
				}
			},
		}
	});

	$( ".mobile-menu-button" ).click( () => {
		if ( $( mobileNavigationMenuSelector ).hasClass( "mm-menu_opened" ) ) {
			menu.API.close();
		}
		else {
			menu.API.open();
		}
	});

	/* Accessible menu */
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .nav-drop-toggle-link").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".nav-drop-wrap").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".nav-drop-wrap").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();

	/* Sticky Header */
	addStickyHeader();
	$(window).scroll(function() {
		addStickyHeader();
	});
});

function addStickyHeader() {
	if ($(window).scrollTop() > 1) {
		$('.nav-section').addClass('sticky');
	}
	else {
		$('.nav-section').removeClass('sticky');
	}
}

